export class InterestModel {
  id: string;
  year: string;
  month: Date;
  interest: number;

  public constructor() {
    this.id = "";
    this.year = "";
    this.month = new Date();
    this.interest = 0;
  }
}
